import axios from 'axios';
var ConfiguratorManagerService = /** @class */ (function () {
    function ConfiguratorManagerService() {
    }
    ConfiguratorManagerService.prototype.get = function () {
        return axios.get('/api/v1/configurator/manage/', {
            withCredentials: true
        });
    };
    ConfiguratorManagerService.prototype.set = function (configuratorDescriptor) {
        return axios.post('/api/v1/configurator/manage/', configuratorDescriptor, {
            withCredentials: true
        });
    };
    return ConfiguratorManagerService;
}());
export default new ConfiguratorManagerService();
